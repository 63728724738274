.ql-overflow {
	/* overflow: visible !important; */
	/* height: auto !important; */
	font-family: "Poppins", sans-serif !important;
	color: #eef2fc;
	text-wrap: wrap !important;
	padding: 0px;
	margin: 0px;
}

.ql-overflow img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 80%;

	object-fit: contain;
	max-width: 900px;
	max-height: 80%;
}

.ql-overflow h1 strong {
	padding: 0px;
	margin: 0px;

	font-size: var(--h1);
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
}

h1 {
	padding: 0px;
	margin: 0px;
	font-size: var(--h1);
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
	width: 100%;
	color: #ffffff;
}

h4 {
	font-size: var(--h4);
	padding-top: 0px;
	margin-top: 0px;
	margin-bottom: 5px;
}

p {
	padding: 2px;
	margin: 5px;
	margin-left: 0px;
	font-size: var(--pp);
	letter-spacing: 0px;
	line-height: 1.3;
	text-wrap: wrap;
	width: 100%;
	font-family: 200;
	/* opacity: 0.8; */
	color: #d7d7d7;
}

li {
	color: #d7d7d7;
}

p.bold {
	font-weight: bold;
	opacity: 1;
}

p strong {
	font-weight: bold;
	opacity: 1;
}

p img {
	opacity: 1;
}

strong {
	font-weight: bold;
	opacity: 1 !important;
	/* color: #89ffdc; */
	/* color: #c1fffb; */
	color: #a7fdf9;
}

h1 strong {
	opacity: 1 !important;
	color: #ffffff;
}

ul {
	padding-top: 0px;
	margin-top: 0px;
	font-family: 200;
	/* opacity: 0.9; */
	font-size: var(--pp);
}

ol {
	padding-top: 0px;
	margin-top: 0px;
	font-family: 300;
	opacity: 0.9;
	font-size: var(--pp);
}

h4 {
	padding-top: 0px;
	margin-top: 0px;
}

.ql-overflow h2 {
	padding: 0px;
	margin: 0px;
	font-size: var(--h2);
	line-height: -10px;
	line-height: 1.2;
	text-wrap: wrap;
	background-image: linear-gradient(to right, #db5bc2, #f78748);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.ql-overflow p ul ol li {
	padding: 0;
	margin: 0;
	/* font-size: 15px; */
	font-size: var(--pp);
	margin-bottom: 10px;
	text-wrap: wrap;
	flex: auto;
	line-break: anywhere;
}

.ql-overflow a {
	font-size: 12px;
	color: #000;
	padding: 10px;
	border-radius: 10px;
	background-color: aqua;
	display: block;
	text-wrap: wrap;
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	/* margin-top: 20px; */
}

.disclaimerImage {
	background-image: url("./pres_bg@1x.png"); /* Replace with the path to your image */
	background-size: contain; /* Scales the image to cover the entire page */
	background-position: center; /* Centers the background image */
	background-repeat: no-repeat; /* Prevents the background from repeating */

	/* Applies a slight blur and opacity */
	filter: blur(5px);
	opacity: 0.8;

	/* Ensures the body takes up the full height of the viewport */
	height: 100vh;
	margin: 0;
	padding: 0;
	width: 110%;
	max-width: 1500px;
	opacity: 0.2;
	margin-left: 20%;
}

.disclaimer h1 {
	font-family: "Poppins", sans-serif !important;
	color: #eef2fc;
	text-wrap: wrap !important;
	padding: 0px;
	margin: 0px;
	padding: 0px;
	margin: 0px;
	color: #d7d7d7;

	font-size: var(--h1);
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
}

.disclaimer p {
	font-family: "Poppins", sans-serif !important;
	color: #d7d7d7;
	font-size: 12px;
}

.loader {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	opacity: 0.5;
	margin: 20px auto;
	padding: 10px;
}

.shimmer {
	opacity: 0.8;
	width: 100%;
	height: 20px;
	border-radius: 8px;
	background-color: #2bacac;
	position: relative;
	overflow: hidden;

	color: grey;
	display: inline-block;
	mask: linear-gradient(-60deg, #000 30%, rgba(0, 0, 0, 0), #000 70%)
		right/350% 100%;
	animation: shimmer 2.5s infinite;
	font-size: 50px;
}

@keyframes shimmer {
	100% {
		mask-position: left;
	}
}
